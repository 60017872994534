import Vue from "vue";
import AuthenticationService from "@/services/AuthenticationService";

const state = {
  authInfo: JSON.parse(localStorage.getItem('authInfo')) || {},
  userInfo: {}
};

const getters = {
  accessToken: state => state.authInfo.access_token || '',
  isAuthenticated: function(state) {
    if (null == state.authInfo || null == state.authInfo.access_token) {
      return false;
    }
    return state.authInfo.access_token !== '';
  }
};

const actions = {

  /**
   * Inicialización del store
   */
  authInit: function({ commit }) {
    commit('INITIALIZE_AUTH_STORE');
  },

  /**
   * Funcion para hacer login
   */
  login: function({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      AuthenticationService.login(credentials.email, credentials.password)
        .then(authInfo => {
          //console.log("login current");
          //console.log(authInfo);
          commit('INITIALIZE_AUTH_INFO', authInfo);
          commit("SET_USER_INFO", credentials.email);
          resolve();
        }).catch(err => {
          Vue.$log.error('Unexpected Error:', JSON.stringify(err));
          reject(err);
        });
    });
  },

  /**
   * Funcion para validar current
   */
  current({ commit }) {
    return new Promise((resolve, reject) => {
      AuthenticationService.getCurrent()
        .then(authInfo => {
          //console.log("authInfo current");
          //console.log(authInfo.data.authorities);
          commit('CURRENT_AUTH_INFO', authInfo);
          resolve();
        }).catch(err => {
          Vue.$log.error('Unexpected Error:', JSON.stringify(err));
          reject(err);
        });
    })
  },

  /**
   * Funcion para hacer logour
   */
  logout: function({ commit }) {
    return new Promise((resolve) => {
      commit("LOGOUT");
      resolve();
    });
  },

};

const mutations = {
  INITIALIZE_AUTH_STORE: function(state) {
    state.authInfo = JSON.parse(localStorage.getItem('authInfo')) || {};
    state.userInfo = {};
  },
  INITIALIZE_AUTH_INFO: function(state, authInfo, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    state.authInfo = authInfo;
    localStorage.setItem("authInfo", JSON.stringify(authInfo));
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  LOGOUT(state) {
    state.authInfo = {};
    state.userInfo = {};
    localStorage.removeItem("authInfo");
    localStorage.removeItem("userInfo");
  },
  CURRENT_AUTH_INFO: function(state, authInfo) {
    state.authcurrent = authInfo;
    localStorage.setItem("authcurrent", JSON.stringify(authInfo));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
