import axios from "axios";
import qs from "qs";
import Vue from "vue";
import authAPI from '@/APIs/boAuthAPI';

export default {

  /**
   * Servicio para realizar login
   */
  login: function(username, password) {
    return new Promise((resolve, reject) => {
      var data = qs.stringify({
        grant_type: "password",
        username: username,
        password: password,
        scope: "ui"
      });

      axios.post(process.env.VUE_APP_AUTH_URL + "/uaa/oauth/token", data, {
          auth: {
            username: process.env.VUE_APP_BASIC_AUTH_USERNAME,
            password: process.env.VUE_APP_BASIC_AUTH_PASSWORD
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          Vue.$log.debug("Http Status: ", response.status);
          resolve(response.data);
        })
        .catch(error => {
          Vue.$log.error("Error Status: ", error.status);
          reject(error);
        });
    });
  },
  getCurrent: function () {
    const url = '/uaa/users/current';
    return authAPI.get(url);
  },

}
