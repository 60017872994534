/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [{
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/ProjectManagement.vue')
        },
        {
          path: '/calendar',
          name: 'Calendario',
          component: () => import('./views/CalendarManagement.vue')
        },
        {
          path: '/promises',
          name: 'Promesas',
          component: () => import('./views/PromiseManagement.vue')
        },
        {
          path: '/bookings',
          name: 'Reservas',
          component: () => import('./views/BookingManagement.vue')
        },
        {
          path: '/quotations',
          name: 'Cotizaciones',
          component: () => import('./views/QuotationManagement.vue')
        },
        {
          path: '/discounts',
          name: 'Descuentos',
          component: () => import('./views/DiscountManagement.vue')
        },
        {
          path: '/clients',
          name: 'Clientes',
          component: () => import('./views/ClientManagement.vue')
        },
        {
          path: '/clientUser',
          name: 'Cliente',
          component: () => import('./views/ClientUserManagement.vue')
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      meta: {
        requiresAuth: false
      },
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router
