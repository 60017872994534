/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue';
import App from './App.vue';

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// Theme Configurations
import '../themeConfig.js';


// Globally Registered Components
import './globalComponents.js';


// Styles: SCSS
import './assets/scss/main.scss';


// Tailwind
import '@/assets/css/main.css';


// Vuex Store
import store from './store/store';

// Vue Router
import router from './router';


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer)


import moment from 'moment';
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});

// Vuejs Logger
import VueLogger from 'vuejs-logger';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);


/**
 * Configuracion para asegurar los recursos de vue. Las rutas deben contener
 * el atributo meta.requiresAuth con valor en true para ser validado.
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (store.getters['AuthenticationStore/isAuthenticated']) {
      next();
    } else {
      next("/pages/login");
    }

  } else {
    next();
  }
});

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';


// Feather font icon
require('./assets/css/iconfont.css');


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
